import axios from "axios";
/**
 * des: 获取Ai绘画跳转URL
 * @params: 无
 * @returns
 */
const getAiDrawUrl = function () {
  return new Promise((resolve, reject) => {
    let url = `api/design/get_sd_url`;
    axios
      .get(url)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
/**
 * des: 获取dingTalk的jsapi鉴权信息
 * @params: 无
 * @returns
 */
const getSignature = function (params) {
  return new Promise((resolve, reject) => {
    let url = `api/user/ding_jsapi`;
    axios
      .get(url, { params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export default {
  getAiDrawUrl,
  getSignature,
};
