import axios from "axios";
let userInfo = {}
const getUserInfo = function (params) {
  return new Promise((resolve, reject) => {
    let url = `api/user/ding_login`;
    axios
      .get(url, { params })
      .then((res) => {
        setUser(res.data && res.data.data || {})
        resolve(res && res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

function getUser() {
  return {...userInfo}
}

function setUser(data) {
  userInfo = data
}
export default {
    getUserInfo,
    getUser
}
