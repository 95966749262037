import Vue from "vue";
import App from "./App.vue";

import "element-ui/lib/theme-chalk/index.css";

Vue.config.productionTip = false;

import services from "./service/index";
Vue.prototype.$services = services;

import { Loading, Message, Notification } from "element-ui";
Vue.use(Loading);
Vue.use(Loading.directive);

import * as dd from "dingtalk-jsapi";
dd.error((err) => {
  console.log("dd-error", JSON.stringify(err))
});

Vue.prototype.$notify = (msg, type) => {
  Notification({
    message: msg,
    type: type || "success",
    duration: 1000,
  });
};

Vue.prototype.$message = (msg, type) => {
  Message({
    message: msg,
    type: type || "success",
  });
};

new Vue({
  render: (h) => h(App),
}).$mount("#app");
