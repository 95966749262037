import * as dd from "dingtalk-jsapi";

function getPlatform() {
  let platform = dd.env.platform
  if (dd.env.platform !== "notInDingTalk") {
    platform = "dingding";
  }
  return platform;
}

function openPage(app_url) {
  console.log("openPage:", getPlatform());
  if (getPlatform() === "dingding") {
    dd.ready(()=>{
      dd.openPageInWorkBenchForPC({
        app_url,
      });
    })
  } else {
    window.open(app_url, "blank");
  }
}

export default {
  openPage,
  getPlatform,
};
