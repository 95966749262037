<template>
  <div class="forbidden-wrap">
    <img src="../images/image-forbidden.jpg" alt="" srcset="" />
    <div class="f-title">暂无权限</div>
    <div class="f-tip">请联系管理员，添加权限。</div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.forbidden-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-family: PingFangSC-Regular;
  margin-top: 10px;
  .f-title {
    font-size: 16px;
    color: #252b3a;
    letter-spacing: 0;
    text-align: center;
    font-weight: 600;
    margin: 30px 0 10px;
  }
  .f-tip {
    font-size: 14px;
    color: #8a8e99;
    letter-spacing: 0;
    text-align: center;
    font-weight: 400;
  }
}
</style>
