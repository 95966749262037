<template>
  <div id="app">
    <template v-if="url">
      <iframe
        :src="url"
        frameborder="0"
        class="frame-wrap"
        @load="loaded"
      ></iframe>
    </template>
    <template v-else>
      <ai-header></ai-header>
      <template v-if="!isLoading">
        <div v-if="isLogin" class="url-wrap"></div>
        <forbidden v-else></forbidden>
      </template>
    </template>
    <div
      v-show="isPageloading"
      class="center-wrap"
      v-loading.fullscreen.lock="isPageloading"
    ></div>
  </div>
</template>

<script>
import Forbidden from "./views/components/Forbidden.vue";
import Header from "./views/components/Headre.vue";
import * as dd from "dingtalk-jsapi";
export default {
  components: {
    AiHeader: Header,
    Forbidden,
  },
  data() {
    return {
      isLoading: true,
      isLogin: false,
      url: "",
      isIframeloading: true,
    };
  },
  computed: {
    isPageloading() {
      if (this.url) {
        console.log(this.isIframeloading, "this.isIframeloading");
        return this.isIframeloading;
      } else {
        console.log(this.isLoading, "this.isLoading");
        return this.isLoading;
      }
    },
  },
  methods: {
    login() {
      let env = this.$services.Platform.getPlatform();
      if (env === "dingding") {
        this.dingLogin()
      } else {
        this.isLoading = false;
      }
    },
    dingLogin() {
      let self = this;
      let corpId = this.getCode("corpId");
      if (!corpId) {
        this.isLoading = false;
        return;
      }
      dd.ready(() => {
        dd.runtime.permission.requestAuthCode({
          corpId,
          env: "dingding",
          onSuccess(info) {
            let code = info.code;
            self.$services.User.getUserInfo({ code })
              .then((res) => {
                console.log(res, 'getUserInfo')
                if (res && res.code == "SDM.0000") {
                  self.url = res.data.url;
                  self.isLogin = true;
                  self.isLoading = false;
                } else {
                  self.isLoading = false;
                }
              })
              .catch(() => {
                self.isLoading = false;
              });
          },
        });
      });
    },
    getCode(key) {
      let url = window.location.search;
      let params = {};
      if (url.indexOf("?") !== -1) {
        url = url.slice(1);
        let strArr = url.split("&");
        for (let i = 0; i < strArr.length; i++) {
          let keyValueArr = strArr[i].split("=");
          params[keyValueArr[0]] = keyValueArr[1];
        }
      }
      return params[key] || "";
    },
    loaded() {
      console.log("ifram-loaded");
      this.isIframeloading = false;
    },
  },
  created() {
    this.login();
  },
};
</script>
<style lang="less">
* {
  list-style: none;
  margin: 0;
  padding: 0;
}
html,
body {
  margin: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  list-style: none;
}
#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  font-family: "PingFangSC-Regular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  width: 100%;
  height: 100%;
  position: relative;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.center-wrap {
  position: absolute;
  text-align: center;
}
.frame-wrap {
  width: 100%;
  height: 98vh;
}
</style>
