import Common from "./Common.service";
import User from "./User.service";
import axios from "axios";
import Cookie from "js-cookie";
import Platform from './Platform.service';

axios.interceptors.request.use(
  (config) => {
    let excludeUrls = [
      "api/user/ding_login",
      'api/user/ding_jsapi'
    ]
    if (excludeUrls.includes(config.url) || Cookie.get("userid")) {
      return config;
    } else {
      let hash = window.location.hash
      if (hash && hash !== "#/") {
        window.location.assign(`${window.location.origin}${window.location.pathname}`)
      }
    }
  },
  (err) => {
    return Promise.reject(err);
  }
);
axios.interceptors.response.use(
  (res) => {
    console.log('s-response', res)
    return res;
  },
  (err) => {
    console.log('e-response', err)
    return Promise.reject(err);
  }
);
export default {
  Common,
  User,
  Cookie,
  Platform,
};
