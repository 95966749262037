<template>
  <div id="header">
    <div class="header-wrap">
      <img src="../images/icon-header.png" alt="" />
      <span>{{userName}}</span>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      userName: ''
    }
  },
  created() {
    let userName = this.$services.Cookie.get('username') || ''
    this.userName = decodeURIComponent(userName)
  }
};
</script>
<style lang="less" scoped>
#header {
  width: 100%;
  min-width: 1280px;
  height: 90px;
  background: #ffffff;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;

  .header-wrap{
    width: 1240px;
    height: 47px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
